import { Button, Card, Form, Input, InputNumber, Space } from "antd";
import SettingApi from "apis/SettingApi";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";

export default function WithdrawLimitSetting() {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { setting } = useAppSelector((state) => state.setting);
    const min_withdraw_amount = setting.find(
        (item) => item.key === "min_withdraw_amount"
    );
    const max_withdraw_auto_amount_per_request = setting.find(
        (item) => item.key === "max_withdraw_auto_amount_per_request"
    );
    const max_withdraw_auto_amount_per_day = setting.find(
        (item) => item.key === "max_withdraw_auto_amount_per_day"
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (min_withdraw_amount
            && max_withdraw_auto_amount_per_request
            && max_withdraw_auto_amount_per_day
        ) {
            form.setFieldsValue({
                min_withdraw_amount: min_withdraw_amount.value,
                max_withdraw_auto_amount_per_request: max_withdraw_auto_amount_per_request.value,
                max_withdraw_auto_amount_per_day: max_withdraw_auto_amount_per_day.value
            });
        }
    }, [min_withdraw_amount, max_withdraw_auto_amount_per_request, max_withdraw_auto_amount_per_day]);

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            const min_withdraw_amount = {
                key: "min_withdraw_amount",
                value: parseFloat(values.min_withdraw_amount),
            };

            const max_withdraw_auto_amount_per_request = {
                key: "max_withdraw_auto_amount_per_request",
                value: parseFloat(values.max_withdraw_auto_amount_per_request),
            };

            const max_withdraw_auto_amount_per_day = {
                key: "max_withdraw_auto_amount_per_day",
                value: parseFloat(values.max_withdraw_auto_amount_per_day),
            };

            const [result1, result2, result3] = await Promise.all([
                SettingApi.updateSetting(min_withdraw_amount),
                SettingApi.updateSetting(max_withdraw_auto_amount_per_request),
                SettingApi.updateSetting(max_withdraw_auto_amount_per_day),
            ]);
            if (result1 && result2 && result3) {
                dispatch(
                    CommonSlice.actions.showNotice({
                        type: "success",
                        message: "Success!",
                        description: "Update successful",
                    })
                );
            }
        } catch (error) {
            dispatch(
                CommonSlice.actions.showNotice({
                    type: "error",
                    message: "Error!",
                    description: "Update failed",
                })
            );
        } finally {
            setLoading(false);
        }
    };
    return (
        <Card title="Withdraw Limit (USDT)" bordered={false} style={{ marginTop: 16 }}>
            <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label={min_withdraw_amount?.description}
                    name="min_withdraw_amount"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
                </Form.Item>

                <Form.Item
                    label={max_withdraw_auto_amount_per_request?.description}
                    name="max_withdraw_auto_amount_per_request"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
                </Form.Item>

                <Form.Item
                    label={max_withdraw_auto_amount_per_day?.description}
                    name="max_withdraw_auto_amount_per_day"
                    rules={[{ required: true, message: "Please input your value!" }]}
                >
                    <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
