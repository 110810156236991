import React from "react";
import { Column } from "@ant-design/plots";
import { useAppSelector } from "stores";
import { Typography } from "antd";

const { Title } = Typography;

export default function DailyActiveUserChart() {
  const { activeUsers } = useAppSelector((state) => state.general);
  const config = {
    data: activeUsers,
    xField: "_id",
    yField: "totalActiveUser",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return (
    <div className="form-item-container">
      <div style={{ marginBottom: 30 }}>
        <Title level={5}>Daily Mining</Title>
      </div>
      {
        //@ts-ignore
        <Column {...config} />
      }
    </div>
  );
}
