const exportedObject = {
  clean: function (obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  },
  checkInt: function (number: number) {
    const result = number - Math.floor(number) !== 0;
    if (result) {
      return number.toFixed(2);
    }
    return number;
  },
  shortenId: function (id?: string) {
    if (id) {
      return `${id.slice(0, 5)}...${id.slice(-6)}`;
    }
    return null;
  },
  shortenEmail: function (email: string) {
    const name = email.substring(0, email.lastIndexOf("@"));
    const domain = email.substring(email.lastIndexOf("@"));
    return `${name.substring(0, 4)}***${domain}`;
  },
  getUnique: function (arr: any, index: any) {
    const unique = arr
      .map((e: any) => e[index])

      // store the keys of the unique objects
      .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);

    return unique;
  },
};

export default exportedObject;
