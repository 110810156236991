import { RequestManager } from "utils";
import config from "configs";

const getUsers = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users`,
    params
  );
};

const getUserDetail = (userId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}`
  );
};

const getUserPredictPnl = (userId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/predict-pnl`
  );
};

const getUserFriendVolume = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/friend-volume`,
    otherParams
  );
};

const getUserCommissionRebate = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/histories`,
    otherParams
  );
};

const getUserPredictHistories = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/predict-histories`,
    otherParams
  );
};

const updateUser = (params: any) => {
  const { id, ...ortherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/users/${id}`,
    ortherParams
  );
};

const getUserHistoryData = (params: any) => {
  const { id, type, ...ortherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/histories/${id}/${type}`,
    ortherParams
  );
};

const exported = {
  getUsers,
  getUserPredictPnl,
  getUserFriendVolume,
  getUserPredictHistories,
  getUserDetail,
  updateUser,
  getUserCommissionRebate,
  getUserHistoryData,
};

export default exported;
