import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Form, Input, InputNumber, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import SettingApi from "apis/SettingApi";
import CommonSlice from "stores/CommonSlice";

export default function MiningSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const mining_amount_per_block = setting.find(
    (item) => item.key === "mining_amount_per_block"
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mining_amount_per_block) {
      form.setFieldsValue({
        mining_amount_per_block: mining_amount_per_block.value,
      });
    }
  }, [mining_amount_per_block]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const mining_amount_per_block = {
        key: "mining_amount_per_block",
        value: parseFloat(values.mining_amount_per_block),
      };
      const response = await SettingApi.updateSetting(mining_amount_per_block);
      if (response) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Mining" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="PC Mining"
          name="mining_amount_per_block"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="PC" />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
