import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import TransactionHistoryApi from "apis/TransactionHistoryApi";
import { defaultMeta } from "utils/Mocks/Common";
import { orArray, orNumber } from "utils/Selector";
import CommonSlice from "./CommonSlice";

const initialState = {
  coinTransaction: {
    datas: [] as CoinTransactionType[],
    meta: defaultMeta as MetaType,
  },
  isRefresh: false as boolean,
};

export const coinTransaction = createAsyncThunk(
  "transactionHistory/coinTransaction",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await TransactionHistoryApi.coinTransactions(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const approve = createAsyncThunk(
  "transactionHistory/approve",
  async (id: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      await TransactionHistoryApi.approve(id);
      thunkApi.dispatch(
        CommonSlice.actions.showNotice({
          type: "success",
          message: "Success!",
          description: "Approve successful",
        })
      );
      return "success";
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const cancel = createAsyncThunk(
  "transactionHistory/cancel",
  async (id: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      await TransactionHistoryApi.cancel(id);
      thunkApi.dispatch(
        CommonSlice.actions.showNotice({
          type: "success",
          message: "Success!",
          description: "Cancel successful",
        })
      );
      return "success";
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const TransactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
    reducers: {
        clearTransactionData: (state, action: PayloadAction<void>) => {
            state.coinTransaction.datas = [];
        },
    },
  extraReducers: (builder) => {
    builder.addCase(coinTransaction.fulfilled, (state, action) => {
      state.isRefresh = false;
      if (action.payload) {
        state.coinTransaction.meta = action.payload.meta;
        state.coinTransaction.datas = action.payload.datas;
      }
    });
    builder.addCase(approve.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(cancel.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
  },
});

export default TransactionHistorySlice;
