import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function AdminGeneralView() {
    const { adminGeneral } = useAppSelector((state) => state.general);

    return (
        <div className="form-item-container">
            <Descriptions title="Admin Statistics">
                <Descriptions.Item label="Total Balance">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalBalance || 0)} USDT</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Token Balance">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalTokenBalance || 0)} USDT</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Withdraw">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalWithdraw || 0)} USDT</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Commission">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalUSDTReferralBonus || 0)} USDT</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Token Commission">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalPCReferralBonus || 0)} PC</Text>
                </Descriptions.Item>
                <Descriptions.Item label="">
                    <Text strong></Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Transfer Charity">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalTransferCharity || 0)} USDT</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Transfer Admin">
                    <Text strong>{Helpers.checkInt(adminGeneral?.totalTransferAdmin || 0)} USDT</Text>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}
