import { Button, Card, Form, Input, InputNumber, Space } from "antd";
import SettingApi from "apis/SettingApi";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";

export default function WithdrawFeeSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const bep20 = setting.find((item) => item.key === "bep20_withdraw_fee");
  const tr20 = setting.find((item) => item.key === "trc20_withdraw_fee");
  const erc20 = setting.find((item) => item.key === "erc20_withdraw_fee");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bep20 && tr20 && erc20) {
      form.setFieldsValue({
        bep20: bep20.value,
        tr20: tr20.value,
        erc20: erc20.value,
      });
    }
  }, [bep20, tr20, erc20]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const bep20Params = {
        key: "bep20_withdraw_fee",
        value: parseFloat(values.bep20),
      };
      const tr20Params = {
        key: "trc20_withdraw_fee",
        value: parseFloat(values.tr20),
      };
      const erc20Params = {
        key: "erc20_withdraw_fee",
        value: parseFloat(values.erc20),
      };

      const [result1, result2, result3] = await Promise.all([
        SettingApi.updateSetting(bep20Params),
        SettingApi.updateSetting(tr20Params),
        SettingApi.updateSetting(erc20Params),
      ]);
      if (result1 && result2 && result3) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Withdraw Fee" bordered={false} style={{ marginTop: 16 }}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label={bep20?.description}
          name="bep20"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
        </Form.Item>

        <Form.Item
          label={tr20?.description}
          name="tr20"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
        </Form.Item>

        <Form.Item
          label={erc20?.description}
          name="erc20"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
