import React, { useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import {
  SearchOutlined
} from "@ant-design/icons";
import type { SelectProps } from "antd/es/select";
import ConnectApi from "apis/ConnectApi";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useAppDispatch, useAppSelector } from "stores";
import ConnectSlice from "stores/ConnectSlice";

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const params = {
      s: value,
    };
    const response = await ConnectApi.search(params);
    const { data } = response;
    const result = data.data.map((item: any) => ({
      value: item.commissionId,
      label: item.username,
    }));
    callback(result);
  };

  if (value) {
    timeout = setTimeout(fake, 500);
  } else {
    callback([]);
  }
};

export default function SearchUser({ query, setDefaultData }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { searchValue } = useAppSelector((state) => state.connect);
  const [data, setData] = useState<SelectProps["options"]>([]);

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  const handleChange = (newValue: string) => {
    dispatch(ConnectSlice.actions.setSearchValue(newValue));
    navigate({
      pathname: "/connects",
      search: queryString.stringify({
        ...query,
        commissionId: newValue,
      }),
    });
    setDefaultData();
  };

  return (
    <Select
      showSearch
      value={searchValue}
      placeholder="Input username ..."
      style={{ width: 250 }}
      defaultActiveFirstOption={false}
      suffixIcon={<SearchOutlined />}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={data}
      onClear={() => {
        navigate({
          pathname: "/connects",
        });
        setData([]);
      }}
      allowClear
    />
  );
}
