import { UserCoinTransactionStatus } from "../Transaction";

export const getLabelNetwork = (network: string) => {
  return `Network is ${network}`;
};

export const getLabelTransactionStatus = (status: string) => {
  const item = UserCoinTransactionStatus.find((item) => item.value === status);
  return `Status transaction is ${item?.label}`;
};

export const getLabelStatusActive = (value: number) => {
  return `Status active is ${value == 1 ? "Active" : "Deactive"}`;
};

export const getLabelSponsor = (sponsor: string) => {
  return `Sponsor is ${sponsor}`;
};

export const getLabelStatus2FA = (value: number) => {
  return `Status Two Factor is ${value == 1 ? "Enable" : "Disable"}`;
};
