import { Col, Row } from "antd";
import React from "react";
import InfoUser from "./InfoUser";
import ListOptions from "./ListOptions";
import PredictPnl from "./PredictPnl";

export default function Content() {
  return (
    <Row gutter={[16, 16]} className="detail-user p-15">
      <Col span={24}>
        <InfoUser />
      </Col>
      <Col span={24}>
        <ListOptions />
      </Col>
    </Row>
  );
}
