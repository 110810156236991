import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SettingApi from "apis/SettingApi";
import CommonSlice from "./CommonSlice";

const initialState = {
  setting: [] as SettingType[],
  isRefresh: false as boolean,
};

export const setting = createAsyncThunk(
  "setting/setting",
  async (_, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await SettingApi.setting();
      const { data } = response;
      if (data) {
        return data.data as SettingType[];
      }
      return [];
    } catch (error) {
      return [];
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const update = createAsyncThunk(
  "setting/update",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      await SettingApi.updateSetting(params);
      return "success";
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setting.fulfilled, (state, action) => {
      state.isRefresh = false;
      if (action.payload) {
        state.setting = action.payload;
      }
    });
  },
});

export default SettingSlice;
