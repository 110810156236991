import React, { useEffect, useMemo, useState } from "react";
import { OrganizationGraph } from "@ant-design/graphs";
import { useAppDispatch, useAppSelector } from "stores";
import ConnectSlice, { getTree } from "stores/ConnectSlice";
import queryString from "query-string";
import { Helpers } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import { Button, Space } from "antd";
import SearchUser from "./components/SearchUser";

export default function PeaceConnect() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<any>({});
  const { token } = useAppSelector((state) => state.profile);
  const { peaceConnect } = useAppSelector((state) => state.connect);
  const [data, setData] = useState<any>({
    id: "",
    value: {
      name: "",
    },
    children: [],
  });

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      commissionId: data.commissionId ? data.commissionId : null,
    };
  }, [location.search]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filter]);

  useEffect(() => {
    if (token && filter) dispatch(getTree(filter));
  }, [filter, token]);

  const handleData = (item: ConnectType) => {
    let newNodes: ConnectNodeType[] = item.nodes;

    const result = newNodes.map((node) => {
      return {
        id: node.id,
        level: node.level,
        value: {
          name: node.user.username,
        },
        children: node.nodes.map((n) => handleData(n)),
      };
    }) as any;

    return {
      id: item.id,
      value: {
        name: item.user.username,
      },
      children: result,
    };
  };
  useEffect(() => {
    setDefaultData();
    if (peaceConnect.length) {
      const newData = handleData(peaceConnect[0]);
      setData(newData);
    }
  }, [peaceConnect]);

  const setDefaultData = () => {
    setData({
      id: "",
      value: {
        name: "",
      },
      children: [],
    });
  };

  const config = {
    data: data,
    autoFit: false,
    nodeCfg: {
      style: (node: any) => {
        return node.value.name === "bonus"
          ? { stroke: "red" }
          : { fill: "#91d5ff" };
      },
    },
    // behaviors: ["drag-canvas", "zoom-canvas", "drag-node"],
    onReady: (graph: any) => {
      graph.on("node:click", (evt: any) => {
        const item = evt.item;
        navigate({
          pathname: "/connects",
          search: queryString.stringify({
            ...query,
            commissionId: item._cfg.id,
          }),
        });
        setDefaultData();
      });
    },
  };

  return (
    <div className="detail-user">
      <Header />
      <div className="content-container">
        <div
          className="search-container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            {filter.commissionId ? (
              <Space wrap>
                <Button
                  onClick={() => {
                    navigate(-1);
                    setDefaultData();
                  }}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(ConnectSlice.actions.setSearchValue(undefined));
                    navigate({ pathname: "/connects" });
                    setDefaultData();
                  }}
                >
                  Reset
                </Button>
              </Space>
            ) : null}
          </div>
          <SearchUser query={query} setDefaultData={setDefaultData} />
        </div>

        <OrganizationGraph {...config} />
      </div>
    </div>
  );
}
